require('../application/application.scss')

// pull in jquery
const $ = require('jquery')

// have to do js _and_ css here because reasons: https://stackoverflow.com/a/54791229
require('@claviska/jquery-minicolors')
require('@claviska/jquery-minicolors/jquery.minicolors.css')

// also have to do js and css here because "webpack-jquery-ui/css" is actually
// "webpack-jquery-ui/css.js" lol
require('webpack-jquery-ui')
require('webpack-jquery-ui/css')

// pull in jquery.qtip
require('qtip2/dist/jquery.qtip.js')

// pull in our vendored jssor.slider here.
// note that this sets window.$Jssor$
require('../application/vendor/jssor/jssor.slider.min.js')

// initialize ujs
require('jquery-ujs')

// polyfill jquery to support our old version of foundation, see
//
// https://stackoverflow.com/a/41262100
//
// this has to come before we require foundation-sites below, and we
// can remove this once we've upgraded foundation
$.fn.load = function (callback) { $(window).on('load', callback) }

// pull foundation in
require('foundation-sites')

// and initialize it
$(function () { $(document).foundation() })
